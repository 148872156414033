import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { CommonOtherResponseType, CommonResponseType, DirectionEnum, USER_ROLE } from 'types'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { AxiosResponse } from 'axios'
import { PM_PER_PAGE, PmPlansListPageTab } from 'pages/PmPage/config'
import { CurrentPMType, CurrentWoInPMType, PMType } from 'slices/pm'
import { NewWorkOrderType, TypeWO, WorkOrderStatus } from 'components/NewWorkOrder/config'
import { checkIsGoodStatus } from 'lib/common'
import { EditWorkOrderType } from 'pages/WorkOrderProfile/components/WorkOrderProfileForm/config'
import { WorkOrderManyAttributeKeys, WorkOrderManyIncludeKeys } from 'api/workOrders'
import dayjs from 'dayjs'

export const getAllPMsList = createAppAsyncThunk('pm/getAllPMsList', async (_, { rejectWithValue, getState }) => {
    const page = getState().pm.pMPaginationPage
    const search = getState().pm.pMsSearchValue
    const role = getState().auth?.user?.role
    const regionId = getState().auth?.user?.regionId || ''
    const sortField = getState().pm.pMsSettings.sortField
    const sortDirection = getState().pm.pMsSettings.sortDirection
    const filters = getState().pm.pMsSettings.filters
    const pMPlansListTab = getState().pm.pMPlansListTab

    const params = {
        offset: (page - 1) * PM_PER_PAGE,
        limit: PM_PER_PAGE,
        keySearchValue: search !== '' ? search : undefined,
        sortField,
        sortDirection,

        regionIdes:
            role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role)
                ? [regionId]
                : filters?.regions?.map((i) => i.value) ?? undefined,
        buildingId: filters?.buildings?.length ? filters?.buildings.map((i) => i.value) : undefined,
        subTypes: filters?.subtypes?.length ? filters?.subtypes : undefined,
        expectedCompletionDateFrom: filters?.completionDateFrom ? filters?.completionDateFrom : undefined,
        expectedCompletionDateTo: filters?.completionDateTo ? filters?.completionDateTo : undefined,
        types: pMPlansListTab === PmPlansListPageTab.CONTRACTED ? [TypeWO.PREVENTIVE_MAINTENANCE] : [TypeWO.RECURRING],
        attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
        includeCriteria: Object.values(WorkOrderManyIncludeKeys),
        isOverdue: filters?.isOverdue || undefined,
        frequency: filters?.frequencies?.length ? filters?.frequencies : undefined,
        subcontractorId: filters?.subcontractor ? filters?.subcontractor.value : undefined,
    }

    try {
        const result = await client.get<CommonResponseType<PMType>, AxiosResponse<CommonOtherResponseType<PMType>>>(
            `/api/workOrders/get-many-folders`,
            { params },
        )
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getAllWorkOrdersInPM = createAppAsyncThunk<any, { pmFolderId: string; isShowPresentPms?: boolean }>(
    'workOrder/getAllWorkOrdersInPM',
    async ({ pmFolderId, isShowPresentPms }, { rejectWithValue, getState, dispatch }) => {
        const pMPlansListTab = getState().pm.pMPlansListTab

        const params = {
            offset: 0,
            limit: 100,
            sortField: 'startDate',
            sortDirection: DirectionEnum.ASC,
            pmFolderId,
            showPM: pMPlansListTab === PmPlansListPageTab.CONTRACTED,
            isShowPresentPms,

            attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
            includeCriteria: Object.values(WorkOrderManyIncludeKeys),
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<CurrentWoInPMType>,
                AxiosResponse<CommonOtherResponseType<CurrentWoInPMType>>
            >(`/api/workOrders/get-many`, { params })

            if (checkIsGoodStatus(result?.status)) {
                const nextPm = result.data?.payload
                    ?.filter((i) => ![WorkOrderStatus.CANCELLED, WorkOrderStatus.COMPLETED].includes(i.status))
                    ?.find((pm) => dayjs(pm.startDate).startOf('day') >= dayjs().startOf('day'))

                if (nextPm) {
                    await dispatch(getPMById({ id: nextPm.id }))
                } else {
                    await dispatch(getPMById({ id: result.data?.payload?.at(-1)?.id ?? '' }))
                }
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPMById = createAppAsyncThunk('pm/getPMById', async ({ id }: { id: string }, { rejectWithValue }) => {
    const params = { workOrderId: id }
    try {
        const result = await client.get<CurrentPMType, AxiosResponse<CurrentPMType>>(
            `/api/workOrders/get-workOrder-by-id`,
            { params },
        )
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const createPM = createAppAsyncThunk<
    any,
    Omit<NewWorkOrderType, 'type'> & {
        isPM: boolean
        checkResponse?: (id?: string, pmFolderId?: string) => Promise<void>
    }
>('pm/createPM', async ({ checkResponse, isPM, ...arg }, { rejectWithValue, getState }) => {
    try {
        const result = await client.post<
            PMType & { pmFolderId: string },
            AxiosResponse<PMType & { pmFolderId: string }>
        >(
            `/api/workOrders/create-workOrder`,
            {
                customerId: getState().auth?.user?.customerId ?? '',
                type: isPM ? TypeWO.PREVENTIVE_MAINTENANCE : TypeWO.RECURRING,
                ...arg,
            },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse(result?.data?.id, result?.data?.pmFolderId)
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editPM = createAppAsyncThunk<
    any,
    Partial<EditWorkOrderType> & { workOrderId: string; checkResponse?: () => void }
>('pm/editPM', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.patch<PMType, AxiosResponse<PMType>>(`/api/workOrders/update-workOrder`, arg, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const cancelPM = createAppAsyncThunk<
    any,
    {
        woId: string
        isCancelAll: boolean
        cancellationReason: string
        checkResponse?: () => void
    }
>('pm/cancelPM', async ({ checkResponse, woId, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.patch(`/api/workOrders/cancel-pm/${woId}`, arg)
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export enum WorkOrderFilesEnum {
    workOrderReportId = 'workOrderReportId',
    workOrderContractId = 'workOrderContractId',
    workOrderFileId = 'wofIId',
}

export type AddFilesToPmRequestType = {
    files: File[]
    saveKey: WorkOrderFilesEnum
    folderId: string
}

export const addFilesToPm = createAppAsyncThunk<any, AddFilesToPmRequestType & { checkResponse?: () => void }>(
    'pm/addFilesToPm',
    async ({ checkResponse, folderId, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(
                `/api/workOrders/work-order-super-files/${folderId}`,
                { ...arg },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deletePmFile = createAppAsyncThunk(
    'pm/deletePmFile',
    async ({ fileIdes, checkResponse }: { fileIdes: string[]; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/workOrders/work-order-super-files`, { params: { fileIdes } })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
