import { PageTabType } from 'components/PageTabPanel'
import * as yup from 'yup'
import { MAX_12_CHARACTERS, MUST_BE_NUMBER, PropertiesType, REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { CommonFileType, CommonType, LengthConstrains } from 'types'

export const breadCrumbsArr = (name: string, isPathFromWoPage: boolean, isPathFromPMPage: boolean) => {
    return [
        {
            name: isPathFromWoPage ? 'Work Orders' : isPathFromPMPage ? 'Preventative Maintenance Plans' : 'Assets',
            link: isPathFromWoPage
                ? `/work-orders?tab=WORK_ORDERS`
                : isPathFromPMPage
                ? `/preventative-maintenance?tab=PM_PLANS`
                : `/assets`,
        },
        {
            name: `${name ?? ''}`,
            link: `#`,
        },
    ]
}

export enum AssetProfileTab {
    DETAILS = 'DETAILS',
    ASSIGNMENT = 'ASSIGNMENT',
    WO_HISTORY = 'WO_HISTORY',
    PM_HISTORY = 'PM_HISTORY',
    FILES_MOPS = 'FILES_MOPS',
    INVENTORY = 'INVENTORY',
    PREFERRED_CONTRACTORS = 'PREFERRED_CONTRACTORS',
    NOTES = 'NOTES',
}

export const tabs: PageTabType[] = [
    {
        id: AssetProfileTab.DETAILS,
        label: 'Details',
    },
    {
        id: AssetProfileTab.ASSIGNMENT,
        label: 'Assignments',
    },
    {
        id: AssetProfileTab.WO_HISTORY,
        label: 'WO History',
    },
    /*{
        id: AssetProfileTab.PM_HISTORY,
        label: 'PM  History',
    },*/
    {
        id: AssetProfileTab.FILES_MOPS,
        label: 'Files & MOPs',
    },
    {
        id: AssetProfileTab.INVENTORY,
        label: 'Current Inventory',
    },
    {
        id: AssetProfileTab.PREFERRED_CONTRACTORS,
        label: 'Preferred Contractors',
    },
    {
        id: AssetProfileTab.NOTES,
        label: 'Notes',
    },
]
export type EditAssetFormType = {
    name: string
    isCritical: boolean
    description?: string
    categoryId: string
    typeId: string
    manufacturer: string
    serialNumber: string
    model: string
    installDate: Date | null
    cost: number | null
    laborValue: number | null
    equipmentId: string
}

export const initEditAsset: EditAssetFormType = {
    name: '',
    isCritical: false,
    description: undefined,
    categoryId: '',
    typeId: '',
    manufacturer: '',
    serialNumber: '',
    model: '',
    installDate: null,
    cost: null,
    laborValue: null,
    equipmentId: '',
}

export type EditLocationAssetType = {
    regionId: string
    buildingId: string
    floorId?: string
    roomId?: string
}

export type EditAssetType = {
    name?: string
    equipmentId?: string
    isCritical?: boolean
    description?: string
    categoryId?: string
    typeId?: string
    manufacturer?: string
    serialNumber?: string
    model?: string
    installDate?: Date
    cost?: number
    laborValue?: number
    regionId: string
    buildingId: string
    floorId?: string
    roomId?: string
    props?: PropertiesType[]
}

export type NewAssignmentFolderType = {
    assetId: string
    name: string
    maxNumberOfAssignments: number
    isCreateEmptyPanels?: boolean
}

export type EditAssignmentFolderType = {
    id: string
    name: string
    maxNumberOfAssignments: number
    isCreateEmptyPanels?: boolean
}

export type EditPanelType = {
    folderId: string
    assetId: string
    assetIdes?: string[]
    roomIdes?: string[]
    objectGroupIdes?: string[]
    assignmentDetails?: string[]
}

type CommonAssignmentPanelType = {
    id: string
    panelId: string
    createdAt: Date | null
    updatedAt: Date | null
}

export type PanelType = {
    assets: Array<
        CommonType & {
            category: CommonType & {
                color: string
                file: CommonFileType | null
            }
            type: CommonType
            AssignmentPanelAsset: CommonAssignmentPanelType & {
                assetId: string
            }
        }
    >
    assignmentDetails: string[]
    createdAt: Date | null
    folderId: string | null
    id: string
    panelNumber: number
    rooms: Array<
        CommonType & {
            AssignmentPanelRoom: CommonAssignmentPanelType & {
                roomId: string
            }
        }
    >
    objectGroups: Array<
        CommonType & {
            category: CommonType & {
                color: string
                file: { url: string } | null
            }
            type: CommonType
            creator: {
                id: string
                firstName: string
                lastName: string
            }
            AssignmentPanelObjectGroup: CommonAssignmentPanelType & {
                objectGroupId: string
            }
        }
    >
    updatedAt: Date | null
    isPresent?: boolean
}

export const editAssetFormSchema = yup.object().shape({
    name: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    equipmentId: yup.string().max(LengthConstrains.L_12, MAX_12_CHARACTERS).trim().nullable(),
    isCritical: yup.boolean().optional(),
    description: yup.string().max(LengthConstrains.L_5000, TOO_LONG).trim().nullable(),
    categoryId: yup.string().required(REQUIRED_FIELD),
    typeId: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    manufacturer: yup.string().max(LengthConstrains.L_20, TOO_LONG).trim().required(REQUIRED_FIELD),
    serialNumber: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    model: yup.string().max(LengthConstrains.L_20, TOO_LONG).trim().required(REQUIRED_FIELD),
    installDate: yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD),
    cost: yup.number().typeError(MUST_BE_NUMBER).min(0).required(REQUIRED_FIELD),
    laborValue: yup.number().typeError(MUST_BE_NUMBER).min(0).required(REQUIRED_FIELD),
})
