import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { CommonOtherResponseType, CommonType } from 'types'
import {
    CurrentInventoryItemType,
    INVENTORY_PER_PAGE,
    InventoryItemType,
    PURCHASE_REQUEST_PER_PAGE,
    PurchaseRequestType,
    RequestInventoryItemsType,
    RequestPurchaseRequestsType,
    InventoryDetailsType,
    VENDOR_PER_PAGE,
    VendorType,
    CurrentInventoryDetailType,
    InventoryItemStatus,
    CurrentVendorType,
    PurchaseRequestStatus,
    CurrentPurchaseRequestType,
} from 'slices/inventoryItems'
import { AxiosResponse } from 'axios'
import { checkIsGoodStatus } from 'lib/common'
import { LocationTransferRequest } from 'components/ActionsWithItemDialogs'
import { IdDependenceType } from 'components/CommonAutoComplete'

export const getInventoryItemsList = createAppAsyncThunk<any, RequestInventoryItemsType>(
    'partsInventory/getInventoryItemsList',
    async (arg, { rejectWithValue }) => {
        try {
            const result = await client.get<
                CommonOtherResponseType<InventoryItemType>,
                AxiosResponse<CommonOtherResponseType<InventoryItemType>>
            >(`/api/inventory/item/many`, { params: { ...arg } })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getCurrentInventoryItem = createAppAsyncThunk<any, { id: string }>(
    'partsInventory/getCurrentInventoryItem',
    async ({ id }, { rejectWithValue }) => {
        try {
            const result = await client.get<CurrentInventoryItemType, AxiosResponse<CurrentInventoryItemType>>(
                `/api/inventory/item/${id}`,
            )

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const allocateWoRequest = createAppAsyncThunk<any, { id: string; workOrderId: string }>(
    'partsInventory/allocateWoRequest',
    async ({ id, workOrderId }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/item/allocate-for-wo/${id}`, { workOrderId })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const closeWoActionRequest = createAppAsyncThunk<any, { workOrderId: string }>(
    'partsInventory/closeWoActionRequest',
    async ({ workOrderId }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/item/close-work-order-action/${workOrderId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type ItemTransferRequestType = {
    id: string
    variant: LocationTransferRequest | null
    companyName?: string
    address?: string
    city?: string
    state?: string
    zip?: string
    note?: string
    buildingId?: string
    supervisorIdes?: string[]
    workOrderId?: string
}

export const transferRequestItem = createAppAsyncThunk<any, ItemTransferRequestType>(
    'partsInventory/transferRequestItem',
    async ({ id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/item/request-transfer/${id}`, { ...arg })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const onHoldItemRequest = createAppAsyncThunk<any, { id: string; onHoldReason: string }>(
    'partsInventory/onHoldItemRequest',
    async ({ id, onHoldReason }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/item/put-on-hold/${id}`, { onHoldReason })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const setAvailableItemRequest = createAppAsyncThunk<any, { id: string; reason?: string }>(
    'partsInventory/setAvailableItemRequest',
    async ({ id, reason }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/item/set-available/${id}`, { reason })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createSimplePartsForWo = createAppAsyncThunk(
    'partsInventory/createSimplePartForWo',
    async ({ key, data }: { key: string; data?: { [key: string]: string | number }[] }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/shit-happens`, { key, data })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const fetchSimpleParts = createAppAsyncThunk(
    'partsInventory/fetchSimpleParts',
    async ({ key }: { key: string }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/shit-happens/${key}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getDetailsInventoryItemsList = createAppAsyncThunk(
    'partsInventory/getDetailsInventoryItemsList',
    async (_, { rejectWithValue, getState }) => {
        const page = getState().inventoryItems.inventoryItemsPaginationPage
        const filters = getState().inventoryItems.inventoryItemsSettings?.filters
        const searchString = getState().inventoryItems.inventoryItemsSearchValue
        const sortField = getState().inventoryItems.inventoryItemsSettings?.sortField
        const sortDirection = getState().inventoryItems.inventoryItemsSettings?.sortDirection

        const params = {
            offset: (page - 1) * INVENTORY_PER_PAGE,
            limit: INVENTORY_PER_PAGE,
            searchString: searchString ? searchString : undefined,
            categoryIdes: filters?.categoryIdes?.length ? filters.categoryIdes : undefined,
            typeIdes: filters?.typeIdes?.length ? filters.typeIdes : undefined,
            hasOpenTransferRequests: filters?.hasOpenTransferRequests ? filters?.hasOpenTransferRequests : undefined,
            hasQuantity: filters?.hasQuantity ? filters?.hasQuantity : undefined,
            hasLowStockAlert: filters?.hasLowStockAlert ? filters?.hasLowStockAlert : undefined,
            isAllocatedForWO: filters?.isAllocatedForWO ? filters?.isAllocatedForWO : undefined,
            avgStockAgeFrom: filters?.avgStockAgeFrom ?? undefined,
            avgStockAgeTo: filters?.avgStockAgeTo ?? undefined,
            sortField,
            sortDirection,
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<InventoryDetailsType>,
                AxiosResponse<CommonOtherResponseType<InventoryDetailsType>>
            >(`/api/inventory/details/many`, { params })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getCurrentInventoryDetail = createAppAsyncThunk<any, { typeId: string }>(
    'partsInventory/getCurrentInventoryDetail',
    async ({ typeId }, { rejectWithValue, getState }) => {
        try {
            const result = await client.get<CurrentInventoryDetailType, AxiosResponse<CurrentInventoryDetailType>>(
                `/api/inventory/details/${typeId}`,
            )

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type EditInventoryDetailsType = {
    currentPrice?: number
    billableCost?: number
    profitMargin?: number
    minQuantity?: number
    maxQuantity?: number
    isEnabledLowStockAlert?: boolean
    isEnabledReOrderAlert?: boolean
    checkResponse?: () => void
}

export const editInventoryDetails = createAppAsyncThunk<any, EditInventoryDetailsType & { typeId: string }>(
    'partsInventory/editInventoryDetails',
    async ({ typeId, checkResponse, ...arg }, { rejectWithValue, getState }) => {
        try {
            const result = await client.patch(`/api/inventory/details/${typeId}`, arg)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getVendorsList = createAppAsyncThunk(
    'partsInventory/getVendorsList',
    async (_, { rejectWithValue, getState }) => {
        const page = getState().inventoryItems.vendorsPaginationPage
        const searchString = getState().inventoryItems.vendorsSearchValue
        const filters = getState().inventoryItems.vendorsSettings?.filters

        const params = {
            offset: (page - 1) * VENDOR_PER_PAGE,
            limit: VENDOR_PER_PAGE,
            searchString: searchString ? searchString : undefined,
            categoryIdes: filters?.categoryIdes?.length ? filters.categoryIdes : undefined,
            typeIdes: filters?.typeIdes?.length ? filters.typeIdes : undefined,
            lastOrderDateFrom: filters?.lastOrderDateFrom ? filters?.lastOrderDateFrom : undefined,
            lastOrderDateTo: filters?.lastOrderDateTo ? filters?.lastOrderDateTo : undefined,
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<VendorType>,
                AxiosResponse<CommonOtherResponseType<VendorType>>
            >(`/api/inventory/vendor/many`, { params })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getVendorsForHook = createAppAsyncThunk<
    any,
    { limit: number; offset: number; typeIdes?: string[]; categoryIdes?: string[] }
>('partsInventory/getVendorsForHook', async (arg, { rejectWithValue }) => {
    try {
        const result = await client.get<
            CommonOtherResponseType<VendorType>,
            AxiosResponse<CommonOtherResponseType<VendorType>>
        >(`/api/inventory/vendor/many`, { params: { ...arg } })

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getCurrentVendor = createAppAsyncThunk(
    'partsInventory/getCurrentVendor',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const result = await client.get<CurrentVendorType, AxiosResponse<CurrentVendorType>>(
                `/api/inventory/vendor/${id}`,
            )

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type NewVendorType = {
    name: string
    website?: string
    address: string
    city: string
    state: string
    zipCode: string
    categoryIdes: string[]
    //typeIdes?: string[]
    note?: string
    phone: string
}

export const createVendor = createAppAsyncThunk<any, NewVendorType & { checkResponse?: (id: string) => void }>(
    'partsInventory/createVendor',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/inventory/vendor`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse(result?.data?.id)
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editVendor = createAppAsyncThunk<any, NewVendorType & { id: string; checkResponse?: () => void }>(
    'partsInventory/createVendor',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/inventory/vendor/${id}`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const removeVendor = createAppAsyncThunk<any, { id: string; checkResponse?: () => void }>(
    'partsInventory/createVendor',
    async ({ checkResponse, id }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/inventory/vendor/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getVendorsForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search?: string; idDependence?: IdDependenceType }
>('partsInventory/getVendorsForAutocomplete', async ({ page, search, idDependence }, { rejectWithValue }) => {
    const params = {
        offset: (page - 1) * VENDOR_PER_PAGE,
        limit: VENDOR_PER_PAGE,
        searchString: search ? search : undefined,
        ...idDependence,
    }

    try {
        const result = await client.get<
            CommonOtherResponseType<VendorType>,
            AxiosResponse<CommonOtherResponseType<VendorType>>
        >(`/api/inventory/vendor/many`, { params })

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getPurchaseRequestList = createAppAsyncThunk(
    'partsInventory/getPurchaseRequestList',
    async (_, { rejectWithValue, getState }) => {
        const page = getState().inventoryItems.purchaseRequestsPaginationPage
        const filters = getState().inventoryItems.purchaseRequestsSettings?.filters

        const params: RequestPurchaseRequestsType = {
            offset: (page - 1) * PURCHASE_REQUEST_PER_PAGE,
            limit: PURCHASE_REQUEST_PER_PAGE,
            categoryIdes: filters?.categoryIdes?.length ? filters.categoryIdes : undefined,
            typeIdes: filters?.typeIdes?.length ? filters.typeIdes : undefined,
            orderDateFrom: filters?.orderDateFrom ? filters?.orderDateFrom : undefined,
            orderDateTo: filters?.orderDateTo ? filters?.orderDateTo : undefined,
            isForWOs: filters?.isForWOs ? filters?.isForWOs : undefined,
            statuses: filters?.statuses?.length ? filters?.statuses : undefined,
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<PurchaseRequestType>,
                AxiosResponse<CommonOtherResponseType<PurchaseRequestType>>
            >(`/api/inventory/purchase-request/many`, { params })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type NewPurchaseRequestType = {
    typeId: string
    quantityRequested: number
    neededByDate: Date | null
    additionalInformation?: {
        manufacturer?: string
        manufacturerPartNumber?: string
        model?: string
    }
    note?: string
    hasOrderArrived: boolean
    hasOrderDetails: boolean
    isNeededForWO: boolean
    workOrderId?: string
    sendToId?: string
    orderDetails?: {
        orderNumber?: string
        orderDate?: Date | null
        quantityOrdered?: number
        orderAmount?: number
        shippingCost?: number
        trackingNumber?: string
        note?: string
        vendorId?: string
    }
    arrivedOrderInformation?: {
        receivedDate?: Date | null
        shelf?: string
        bin?: string
        buildingId?: string
        roomId?: string
        storageLocationInformation?: string
    }
}
export const createPurchaseRequest = createAppAsyncThunk<
    any,
    NewPurchaseRequestType & { checkResponse?: (id?: string) => Promise<void> }
>('partsInventory/createPurchaseRequest', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/inventory/purchase-request`, { ...arg })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse(result?.data?.id)
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editPurchaseRequest = createAppAsyncThunk<
    any,
    Partial<NewPurchaseRequestType> & { checkResponse?: () => Promise<void>; id: string }
>('partsInventory/editPurchaseRequest', async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.patch(`/api/inventory/purchase-request/${id}`, { ...arg })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const createPurchaseRequestFiles = createAppAsyncThunk<
    any,
    { files: File[]; id: string; checkResponse?: () => void }
>('partsInventory/createPurchaseRequestFiles', async ({ checkResponse, files, id }, { rejectWithValue }) => {
    try {
        const result = await client.post(
            `/api/inventory/purchase-request/files/${id}`,
            { files },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const removePurchaseRequestFiles = createAppAsyncThunk<
    any,
    { fileIdes: string[]; id: string; checkResponse?: () => void }
>('partsInventory/removePurchaseRequestFiles', async ({ checkResponse, fileIdes, id }, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/inventory/purchase-request/files/${id}`, { params: { fileIdes } })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getCurrentPurchaseRequest = createAppAsyncThunk(
    'partsInventory/getCurrentPurchaseRequest',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const result = await client.get<CurrentPurchaseRequestType, AxiosResponse<CurrentPurchaseRequestType>>(
                `/api/inventory/purchase-request/${id}`,
            )

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const removeItemRequest = createAppAsyncThunk<any, { id: string; checkResponse?: () => void }>(
    'partsInventory/removeItemRequest',
    async ({ id, checkResponse }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/inventory/item/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type EditItemRequestType = {
    status: InventoryItemStatus | null
    manufacturer: string
    note?: string
    manufacturerPartNumber: string
    model: string
    stockAge: number
    price: number
    onHoldReason?: string
    buildingId?: string
    roomId?: string
    shelf?: string
    bin?: string
    workOrderId?: string
    transferRequest?: {
        variant: LocationTransferRequest | null
        companyName?: string | null
        address?: string | null
        city?: string | null
        state?: string | null
        zip?: string | null
        note?: string | null
        buildingId?: string | null
        supervisorIdes?: string[] | null
        workOrderId?: string | null
    }
}
export const editItemRequest = createAppAsyncThunk<
    any,
    EditItemRequestType & { id: string; checkResponse?: () => void }
>('partsInventory/editItemRequest', async ({ id, checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.put(`/api/inventory/item/${id}`, { ...arg })

        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export type NewInventoryItemType = {
    typeId: string
    quantity: number
    totalCost: number
    model: string
    manufacturer: string
    manufacturerPartNumber: string
    note?: string
    hasOrderInfo?: boolean
    orderDetails?: {
        orderNumber: string
        orderDate?: Date | null
        quantityOrdered: number
        orderAmount: number
        shippingCost?: number
        trackingNumber?: string
        //note: string
        vendorId?: string
    }
    buildingId?: string
    roomId?: string
    shelf?: string
    bin?: string
}

export const createInventoryItem = createAppAsyncThunk<any, NewInventoryItemType & { checkResponse?: () => void }>(
    'partsInventory/createInventoryItem',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/inventory/item`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addOrderDetailsFiles = createAppAsyncThunk<
    any,
    { orderDetailsId: string; files: File[]; checkResponse?: () => void }
>('partsInventory/addInventoryItemFilese', async ({ orderDetailsId, files, checkResponse }, { rejectWithValue }) => {
    try {
        const result = await client.post(
            `/api/inventory/item/files/${orderDetailsId}`,
            { files },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )

        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export type OrderHistoryType = {
    id: string
    orderDate: Date | null
    quantityOrdered: number
    orderAmount: number
    shippingCost: number
    createdAt: Date | null
    trackingNumber: string
    orderNumber: null
    vendor: CommonType & {
        website: string
    }
}

export const getOrderHistoryForHook = createAppAsyncThunk<any, { limit: number; offset: number; typeId: string }>(
    'partsInventory/getOrderHistoryForHook',
    async ({ typeId, limit, offset }, { rejectWithValue }) => {
        try {
            const result = await client.get<
                CommonOtherResponseType<OrderHistoryType>,
                AxiosResponse<CommonOtherResponseType<OrderHistoryType>>
            >(`/api/inventory/purchase-request/order-history/${typeId}`, { params: { limit, offset } })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type OrderDetailsType = Omit<OrderHistoryType, 'createdAt'> & {
    note: string
    purchaseRequest: {
        id: string
        receivedDate: Date | null
        status: PurchaseRequestStatus
    }
    files: []
}

export const getOrderDetails = createAppAsyncThunk<any, { orderId: string }>(
    'partsInventory/getOrderDetails',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            const result = await client.get<
                CommonOtherResponseType<OrderDetailsType>,
                AxiosResponse<CommonOtherResponseType<OrderDetailsType>>
            >(`/api/inventory/purchase-request/order-details/${orderId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
