import { RefObject, useEffect } from 'react'

const useClose = <T extends Element>(ref: RefObject<T>, onClose: () => void, isNotUsing?: boolean) => {
    useEffect(() => {
        if (isNotUsing) {
            return
        }

        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('touchstart', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.addEventListener('touchstart', handleClickOutside)
        }
    }, [isNotUsing])
}

export default useClose
