import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { WORK_ORDERS_PER_PAGE } from 'pages/WorkOrdersPage/config'
import { CommonOtherResponseType, CommonResponseType, DirectionEnum, NOTIFICATION_STATUS, USER_ROLE } from 'types'
import { checkIsGoodStatus } from 'lib/common'
import {
    CalendarMonthWOList,
    CurrentWorkOrderTechnicianType,
    CurrentWorkOrderType,
    DeleteEntitiesFromWOType,
    JoinWOType,
    UpdateWOTechnicianType,
    WorkOrderType,
} from 'slices/workOrder'
import { AxiosResponse } from 'axios'
import { NewWorkOrderType, WorkOrderStatus } from 'components/NewWorkOrder/config'
import { EditWorkOrderType } from 'pages/WorkOrderProfile/components/WorkOrderProfileForm/config'
import {
    AddFilesToWorkOrderRequestType,
    GetWorkOrderFilesType,
} from 'pages/WorkOrderProfile/components/FilesWorkOrderTab/config'
import { INVENTORY_PER_PAGE, InventoryItemStatus, InventoryItemType } from 'slices/inventoryItems'
import sendNotification from 'lib/notification'
import { IdDependenceType } from 'components/CommonAutoComplete'

export enum WorkOrderManyAttributeKeys {
    description = 'description',
    type = 'type',
    subType = 'subType',
    scenario = 'scenario',
    specialInstructions = 'specialInstructions',
    priority = 'priority',
    status = 'status',
    startDate = 'startDate',
    expectedCompletionDate = 'expectedCompletionDate',
    endDate = 'endDate',
    displayOnCalendars = 'displayOnCalendars',
    subcontractorFee = 'subcontractorFee',
    weekDay = 'weekDay',
    weekMonth = 'weekMonth',
    year = 'year',
    frequencyPM = 'frequencyPM',
    isContractedPM = 'isContractedPM',
    isOverdue = 'isOverdue',
    contractValuePM = 'contractValuePM',
    paymentTermsPM = 'paymentTermsPM',
    emergencyPlanId = 'emergencyPlanId',
    plansId = 'plansId',
    mopsId = 'mopsId',
    workTechCount = 'workTechCount',
    additionalExpenses = 'additionalExpenses',
    estimatedLaborHours = 'estimatedLaborHours',
    actualLaborHours = 'actualLaborHours',
}

export enum WorkOrderManyIncludeKeys {
    creator = 'creator',
    //bucket = 'bucket',
    //building = 'building',
    asset = 'asset',
    technicians = 'technicians',
    technicians_with_details = 'technicians_with_details',
    bucket_with_user = 'bucket_with_user',
    building_with_region = 'building_with_region',
    //floor = 'floor',
    subcontractor = 'subcontractor',
    //room = 'room',
    statuses = 'statuses',
    requestor_files = 'requestor_files',
}
export const getAllWorkOrdersList = createAppAsyncThunk(
    'workOrder/getManyWorkOrdersList',
    async (_, { rejectWithValue, getState }) => {
        const page = getState().workOrders.workOrderPaginationPage
        const search = getState().workOrders.workOrdersSearchValue
        const role = getState().auth?.user?.role
        const regionId = getState().auth?.user?.regionId || ''
        const sortField = getState().workOrders.workOrdersSettings.sortField
        const sortDirection = getState().workOrders.workOrdersSettings.sortDirection
        const filters = getState().workOrders.workOrdersSettings.filters

        const params = {
            offset: (page - 1) * WORK_ORDERS_PER_PAGE,
            limit: WORK_ORDERS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            sortField,
            sortDirection,

            regionIdes: role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role) ? [regionId] : undefined,
            types: filters?.types?.length ? filters?.types : undefined,
            subTypes: filters?.subTypes?.length ? filters?.subTypes : undefined,
            priorities: filters?.priorities?.length ? filters?.priorities : undefined,
            expectedCompletionDateFrom: filters?.completionDateFrom ? filters?.completionDateFrom : undefined,
            expectedCompletionDateTo: filters?.completionDateTo ? filters?.completionDateTo : undefined,
            buildingId: filters?.buildings?.length ? filters?.buildings.map((i) => i.value) : undefined,
            byCreator: role === USER_ROLE.REQUESTOR ? true : undefined,
            //assetCategoriesId: filters?.assetCategoriesId?.length ? filters?.assetCategoriesId : undefined,
            //assetTypesId: filters?.assetTypesId?.length ? filters?.assetTypesId : undefined,
            estimatedLaborHoursFrom: filters?.minLaborHours ?? undefined,
            estimatedLaborHoursTo: filters?.maxLaborHours ?? undefined,

            attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
            includeCriteria: Object.values(WorkOrderManyIncludeKeys),
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<WorkOrderType>,
                AxiosResponse<CommonOtherResponseType<WorkOrderType>>
            >(`/api/workOrders/get-many`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getWorkOrderById = createAppAsyncThunk(
    'workOrder/getWorkOrderById',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        const params = { workOrderId: id }
        try {
            const result = await client.get<CurrentWorkOrderType, AxiosResponse<CurrentWorkOrderType>>(
                `/api/workOrders/get-workOrder-by-id`,
                { params },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createWorkOrder = createAppAsyncThunk<any, NewWorkOrderType & { checkResponse?: (id?: string) => void }>(
    'workOrder/createWorkOrder',
    async ({ checkResponse, ...arg }, { rejectWithValue, getState }) => {
        try {
            const result = await client.post<WorkOrderType, AxiosResponse<WorkOrderType>>(
                `/api/workOrders/create-workOrder`,
                { customerId: getState().auth?.user?.customerId ?? '', ...arg },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse(result?.data?.id)
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetPreferredContractors = createAppAsyncThunk(
    'workOrder/getAssetPreferredContractors',
    async ({ id, page }: { id: string; page: number }, { rejectWithValue }) => {
        const params = {
            assetId: id,
            page,
            size: 25,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
        }
        try {
            const result = await client.get<any, AxiosResponse<any>>(
                `/api/subcontractor/get-asset-preferred-contractor-list`,
                { params },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editWorkOrder = createAppAsyncThunk<
    any,
    Partial<EditWorkOrderType> & { workOrderId: string; checkResponse?: () => void }
>('workOrder/editWorkOrder', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.patch<WorkOrderType, AxiosResponse<WorkOrderType>>(
            `/api/workOrders/update-workOrder`,
            arg,
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const addAssetToWorkOrder = createAppAsyncThunk(
    'workOrder/addAssetToWorkOrder',
    async (
        {
            assetsId,
            workOrderId,
            checkResponse,
        }: { assetsId: string[]; workOrderId: string; checkResponse?: () => void },
        { rejectWithValue },
    ) => {
        try {
            const result = await client.post<any, AxiosResponse<any>>(`/api/workOrders/add-asset-to-workOrder`, {
                assetsId,
                workOrderId,
            })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssetFromWorkOrder = createAppAsyncThunk(
    'workOrder/deleteAssetFromWorkOrder',
    async (
        { assetId, workOrderId, checkResponse }: { assetId: string; workOrderId: string; checkResponse?: () => void },
        { rejectWithValue },
    ) => {
        try {
            const result = await client.delete<any, AxiosResponse<any>>(`/api/workOrders/delete-asset-from-workOrder`, {
                params: { assetId, workOrderId },
            })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetWorkOrdersList = {
    offset?: number
    search?: string
    limit?: number
    customerId?: string
    startDate?: string
    endDate?: string
    showPM?: boolean
    types?: string[]
    assetId?: string
    subcontractorId?: string
}

export const getWorkOrdersList = createAppAsyncThunk(
    'workOrder/getWorkOrdersList',
    async (
        { offset, search, customerId, limit, startDate, endDate, showPM, types }: GetWorkOrdersList,
        { rejectWithValue, getState },
    ) => {
        const params = {
            offset,
            limit: limit || WORK_ORDERS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            customerId,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            startDate,
            endDate,
            showPM,
            types,
        }

        try {
            const result = await client.get<
                CommonResponseType<WorkOrderType>,
                AxiosResponse<CommonResponseType<WorkOrderType>>
            >(`/api/workOrders/get-workOrder-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getWorkOrdersListForLayout = createAppAsyncThunk(
    'workOrder/getWorkOrdersListForLayout',
    async (
        { offset, search, customerId, limit, startDate, endDate, showPM }: GetWorkOrdersList,
        { rejectWithValue, getState },
    ) => {
        const params = {
            offset,
            limit: limit || WORK_ORDERS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            customerId,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            startDate,
            endDate,
            showPM,
        }

        try {
            const result = await client.get<
                CommonResponseType<WorkOrderType>,
                AxiosResponse<CommonResponseType<WorkOrderType>>
            >(`/api/workOrders/get-workOrder-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetWorkOrdersListByEntity = {
    offset: number
    userId?: string
    statuses?: Array<WorkOrderStatus>
    isOverdue?: boolean
}

export const getWorkOrdersListByEntity = createAppAsyncThunk(
    'users/getWorkOrdersListByEntity',
    async (params: GetWorkOrdersListByEntity, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/workOrders/get-workOrder-list-by-entity`, {
                params: {
                    ...params,
                    limit: WORK_ORDERS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetWorkOrdersListForAutocomplete = {
    offset: number
    keySearchValue?: string
    statuses?: Array<WorkOrderStatus>
    idDependence?: IdDependenceType
    limit?: number
}

export const getWorkOrdersListForAutocomplete = createAppAsyncThunk(
    'users/getWorkOrdersListForAutocomplete',
    async (
        { idDependence, limit = WORK_ORDERS_PER_PAGE, ...params }: GetWorkOrdersListForAutocomplete,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/workOrders/get-many`, {
                params: {
                    ...params,
                    limit,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                    ...idDependence,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetCalendarWorkOrdersList = {
    search?: string
    customerId?: string
    startDate?: string
    endDate?: string
    showPM?: boolean
    types?: string[]
    assetId?: string
    subcontractorId?: string
    isDayCalendar?: boolean
    timezoneDifference?: number
}
export const getWorkOrdersMonthCalendarList = createAppAsyncThunk(
    'workOrder/getWorkOrdersMonthCalendarList',
    async (
        {
            search,
            customerId,
            startDate,
            endDate,
            showPM,
            isDayCalendar = false,
            timezoneDifference,
        }: GetCalendarWorkOrdersList,
        { rejectWithValue, getState },
    ) => {
        const params = {
            keySearchValue: search !== '' ? search : undefined,
            customerId,
            sortField: 'number',
            sortDirection: DirectionEnum.ASC,
            startDate,
            endDate,
            showPM,
            isDayCalendar,
            timezoneDifference,
        }

        try {
            const result = await client.get(`/api/workOrders/get-workOrder-list-for-calendar`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addFilesToWorkOrder = createAppAsyncThunk<
    any,
    AddFilesToWorkOrderRequestType & { checkResponse?: () => void }
>('workOrder/addFilesToWorkOrder', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(
            `/api/workOrders/files/create-workOrder-file`,
            { ...arg },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getWorkOrderFilesList = createAppAsyncThunk<any, GetWorkOrderFilesType>(
    'workOrder/getWorkOrderFilesList',
    async (arg, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/workOrders/files/get-workOrder-files`, { params: { ...arg } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteWorkOrderFile = createAppAsyncThunk(
    'workOrder/deleteWorkOrderFile',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/workOrders/files/delete-workOrder-file`, { params: { id } })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addTeamToWorkOrder = createAppAsyncThunk<
    any,
    { bucketsId: string[]; workOrderId: string; checkResponse?: () => void }
>('workOrder/addTeamToWorkOrder', async ({ bucketsId, workOrderId, checkResponse }, { rejectWithValue, dispatch }) => {
    const data = { bucketsId, workOrderId }
    try {
        const result = await client.post(`/api/workOrders/add-bucket-to-workOrder`, data)

        if (checkIsGoodStatus(result.status)) {
            await dispatch(getWorkOrderById({ id: workOrderId }))
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const removeTeamFromWorkOrder = createAppAsyncThunk<
    any,
    { bucketId: string; workOrderId: string; isPM?: boolean }
>(
    'workOrder/removeTeamFromWorkOrder',
    async ({ bucketId, workOrderId, isPM = false }, { rejectWithValue, dispatch }) => {
        const params = { bucketId, workOrderId }
        try {
            const result = await client.delete(`/api/workOrders/delete-bucket-from-workOrder`, { params })

            if (checkIsGoodStatus(result.status) && !isPM) {
                await dispatch(getWorkOrderById({ id: workOrderId }))
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getWorkOrderTechnicians = createAppAsyncThunk<any, { workOrderId: string }>(
    'workOrder/getWorkOrderTechnicians',
    async ({ workOrderId }, { rejectWithValue }) => {
        try {
            const result = await client.get<
                CurrentWorkOrderTechnicianType[],
                AxiosResponse<CurrentWorkOrderTechnicianType[]>
            >(`/api/workOrders/get-workOrder-tech`, { params: { workOrderId } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteEntitiesFromWorkOrder = createAppAsyncThunk(
    'workOrder/deleteEntitiesFromWorkOrder',
    async (
        { checkResponse, ...arg }: DeleteEntitiesFromWOType & { checkResponse?: () => void },
        { rejectWithValue },
    ) => {
        try {
            const result = await client.patch<any, AxiosResponse<any>>(
                `/api/workOrders/delete-entities-from-workOrder`,
                { ...arg },
            )

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const joinToWorkOrder = createAppAsyncThunk<any, JoinWOType & { checkResponse?: () => void }>(
    'workOrder/getWorkOrderTechnicians',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post<
                CurrentWorkOrderTechnicianType[],
                AxiosResponse<CurrentWorkOrderTechnicianType[]>
            >(`/api/workOrders/join-to-workOrder`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateWOTechnician = createAppAsyncThunk<any, UpdateWOTechnicianType & { checkResponse?: () => void }>(
    'workOrder/updateWOTechnician',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(
                `/api/workOrders/update-workOrder-technician`,
                { ...arg },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const removeTechnicianFromWorkOrder = createAppAsyncThunk<any, { technicianId: string; workOrderId: string }>(
    'workOrder/updateWOTechnician',
    async ({ workOrderId, technicianId }, { rejectWithValue, dispatch }) => {
        const params = { technicianId, workOrderId }
        try {
            const result = await client.delete(`/api/workOrders/delete-technician-from-workOrder`, { params })

            if (checkIsGoodStatus(result?.status)) {
                await dispatch(getWorkOrderById({ id: workOrderId }))
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getWorkOrdersListByAsset = createAppAsyncThunk(
    'workOrder/getWorkOrdersListByAsset',
    async ({ offset, limit, assetId, showPM, types, subcontractorId }: GetWorkOrdersList, { rejectWithValue }) => {
        const params = {
            offset,
            limit: limit || WORK_ORDERS_PER_PAGE,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            assetId: [assetId],
            showPM,
            types,
            subcontractorId,

            attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
            includeCriteria: Object.values(WorkOrderManyIncludeKeys),
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<WorkOrderType>,
                AxiosResponse<CommonOtherResponseType<WorkOrderType>>
            >(`/api/workOrders/get-many`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getInventoryItemsListForWo = createAppAsyncThunk<
    any,
    {
        typeIdes?: string[]
        status?: InventoryItemStatus
        allocatedToWorkOrderId?: string
    }
>('workOrder/getInventoryItemsListForWo', async (arg, { rejectWithValue, getState }) => {
    const page = getState().workOrders.inventoryItemsInWo.page

    const params = {
        limit: INVENTORY_PER_PAGE,
        offset: INVENTORY_PER_PAGE * (page - 1),
        ...arg,
    }
    try {
        const result = await client.get<
            CommonOtherResponseType<InventoryItemType>,
            AxiosResponse<CommonOtherResponseType<InventoryItemType>>
        >(`/api/inventory/item/many`, { params })

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const sendWorkOrderSummary = createAppAsyncThunk<
    any,
    { userIdes?: string[]; emails?: string[]; file: File; checkResponse?: () => void }
>('workOrder/getWorkOrderTechnicians', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post<
            CurrentWorkOrderTechnicianType[],
            AxiosResponse<CurrentWorkOrderTechnicianType[]>
        >(`/api/workOrders/send-workOrder-summary`, { ...arg }, { headers: { 'Content-Type': 'multipart/form-data' } })

        if (checkIsGoodStatus(result?.status)) {
            sendNotification(
                'The Work Order summary was successfully sent by mail to the selected recipients.',
                NOTIFICATION_STATUS.SUCCESS,
            )
            checkResponse && checkResponse()
        }
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const sendWoToSubcontractor = createAppAsyncThunk<
    any,
    { id: string; emails: string[]; file: File; checkResponse?: () => void }
>('workOrder/sendWoToSubcontractor', async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(
            `/api/workOrders/send-work-order-details/${id}`,
            { ...arg },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )

        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getWoDetails = createAppAsyncThunk<any, { id: string }>(
    'workOrder/getWoDetails',
    async ({ id }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/workOrders/get-work-order-details/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
