import { PageTabType } from 'components/PageTabPanel'
import * as T from 'components/Table'
import { USER_ROLE } from 'types'
import { TableColumnType } from 'components/Table'
import { FiltersForReportsType } from 'components/CommonSavedView'
import { AssetReportsFiltersType } from 'hooks/assetsHooks/useAssetFiltersForReports'
import { WorkOrderFiltersType } from 'slices/workOrder'

export enum WorkOrderKeysEnum {
    WO_NUMBER = 'WO Number',
    DATE_CREATED = 'Date Created',
    TITLE = 'Title',
    TYPE = 'Type/Subtype',
    PRIORITY = 'Priority',
    ASSET = 'Asset',
    STATUS = 'Status',
    ASSIGNED_TO = 'Assigned To',
    LAST_UPDATED = 'Last Updated',
    REQUESTOR = 'Requestor',
    DESCRIPTION = 'Description',
    ASSET_IS_CRITICAL = 'Asset Is Critical',
    LOCATION = 'Location',
    START_DATE = 'Start Date',
    DUE_DATE = 'Due Date',
    COMPLETION_DATE = 'Completion Date',
    ESTIMATE_LABOR_HOURS = 'Est. Labor Hours',
    ACTUAL_LABOR_HOURS = 'Actual Labor Hours',
    LABOR_COST = 'Labor Cost',
    TOTAL_COST = 'Total Cost',
    SUBCONTRACTOR = 'Subcontractor',
    SUBCONTRACTOR_FEE = 'Subcontractor Fee',
    FILES = 'Files',
}
export const requiredWorkOrderColumns: T.TableColumnType[] = [
    { label: WorkOrderKeysEnum.WO_NUMBER, name: 'WoNumber', sort: false },
    { label: WorkOrderKeysEnum.DATE_CREATED, name: 'dateCreated', sort: false },
]
export const initialWorkOrderColumns: T.TableColumnType[] = [
    { label: WorkOrderKeysEnum.TITLE, name: 'title', sort: true },
    { label: WorkOrderKeysEnum.TYPE, name: 'type', sort: true },
    { label: WorkOrderKeysEnum.PRIORITY, name: 'priority', sort: true },
    { label: WorkOrderKeysEnum.ASSET, name: 'asset', sort: false },
    { label: WorkOrderKeysEnum.STATUS, name: 'status', sort: true },
    { label: WorkOrderKeysEnum.ASSIGNED_TO, name: 'assignedTo', sort: false },
    { label: WorkOrderKeysEnum.LAST_UPDATED, name: 'lastUpdated', sort: false },
]
export const workOrderTableColumns: T.TableColumnType[] = initialWorkOrderColumns.concat([
    { label: WorkOrderKeysEnum.REQUESTOR, name: 'requestor', sort: false },
    { label: WorkOrderKeysEnum.DESCRIPTION, name: 'description', sort: true },
    { label: WorkOrderKeysEnum.ASSET_IS_CRITICAL, name: 'assetIsCritical', sort: false },
    { label: WorkOrderKeysEnum.LOCATION, name: 'location', sort: false },
    { label: WorkOrderKeysEnum.START_DATE, name: 'startDate', sort: true },
    { label: WorkOrderKeysEnum.DUE_DATE, name: 'dueDate', sort: false },
    { label: WorkOrderKeysEnum.COMPLETION_DATE, name: 'completionDate', sort: false },
    { label: WorkOrderKeysEnum.ESTIMATE_LABOR_HOURS, name: 'estLaborHours', sort: false },
    //{ label: WorkOrderKeysEnum.ACTUAL_LABOR_HOURS, name: 'actualLaborHours', sort: false },
    { label: WorkOrderKeysEnum.LABOR_COST, name: 'laborCost', sort: false },
    { label: WorkOrderKeysEnum.TOTAL_COST, name: 'totalCost', sort: false },
    { label: WorkOrderKeysEnum.SUBCONTRACTOR, name: 'subcontractor', sort: false },
    //{ label: WorkOrderKeysEnum.SUBCONTRACTOR_FEE, name: 'subcontractorFee', sort: true },
    //{ label: WorkOrderKeysEnum.FILES, name: 'files', sort: false },
])

export enum WorkOrdersListPageTab {
    WORK_ORDERS = 'WORK_ORDERS',
    TEAMS = 'TEAMS',
    SAVED_VIEWS = 'SAVED_VIEWS',
}

export const getTabs = (userRole?: USER_ROLE): PageTabType[] => {
    const baseTabs = [
        {
            id: WorkOrdersListPageTab.WORK_ORDERS,
            label: 'Work Orders',
        },
        {
            id: WorkOrdersListPageTab.SAVED_VIEWS,
            label: 'Saved Views',
        },
    ]

    if (userRole && [USER_ROLE.SUPERVISOR, USER_ROLE.ADMIN].includes(userRole)) {
        baseTabs.splice(1, 0, {
            id: WorkOrdersListPageTab.TEAMS,
            label: 'Teams',
        })
    }

    return baseTabs
}

export const WORK_ORDERS_PER_PAGE = 50

export const getWorkOrderColumns = (columns: string[]) => {
    const otherColumns: TableColumnType[] = []
    for (const val of columns.slice(requiredWorkOrderColumns.length)) {
        const currentColumn = workOrderTableColumns.find((item) => item.label === val)

        if (currentColumn) {
            otherColumns.push(currentColumn)
        }
    }
    return otherColumns
}

export const getWorkOrderFilters = (filter?: FiltersForReportsType<AssetReportsFiltersType>[]) => {
    const workOrderFilters: WorkOrderFiltersType = {}

    if (filter) {
        for (const val of Object.values(filter)) {
            if ('buildingId' in val) {
                // @ts-ignore
                workOrderFilters.buildings = val?.buildingId?.map((item: string) => ({ value: item, label: '' }))
            }
            if ('priority' in val) {
                workOrderFilters.priorities = val.priority as string[]
            }
            if ('endDate' in val) {
                // @ts-ignore
                if ('>=' in val.endDate && val.endDate['>=']) {
                    workOrderFilters.completionDateFrom = new Date(val.endDate['>='] as Date)
                }
                // @ts-ignore
                if ('<=' in val.endDate && val.endDate['<=']) {
                    workOrderFilters.completionDateTo = new Date(val.endDate['<='] as Date)
                }
            }
            /*if ('filtersOr' in val) {


                // @ts-ignore
                if (val?.filtersOr.find((item: { [key: string]: string }) => 'types' in item)) {
                    // @ts-ignore
                    workOrderFilters.types = val?.filtersOr.find(
                        (item: { [key: string]: string }) => 'types' in item,
                    )?.types
                }
            }
            if ('filtersOr' in val) {
                // @ts-ignore
                if (val?.filtersOr.find((item: { [key: string]: string }) => 'subTypes' in item)) {
                    // @ts-ignore
                    workOrderFilters.subTypes = val?.filtersOr.find(
                        (item: { [key: string]: string }) => 'subTypes' in item,
                    )?.subTypes
                }
            }*/
        }
    }
    return workOrderFilters
}
