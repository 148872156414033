import * as T from 'components/Table'

export enum TeamKeysEnum {
    NAME = 'Team Name',
    REGION = 'Region',
    TECHNICIANS = 'Technicians',
    AVAILABILITY = 'Availability',
    REQUIRES_APPROVAL = 'Requires Approval',
    WO_TYPES = 'WO Types',
    SUPERVISORS = 'Supervisors',
    ASSIGMENT_POLICY = 'Assignment Policy',
    AVG_WO_COST = 'Avg WO Cost',
    AVG_HOURLY_RATE = 'Avg Hourly Rate',
    TOTAL_WO_COMPLETED = 'Total WOs Completed',
    AVG_MONTHLY_WO = 'Avg Monthly WOs',
}
export const requiredTeamColumns: T.TableColumnType[] = [{ label: TeamKeysEnum.NAME, name: 'name', sort: true }]
export const initialTeamColumns: T.TableColumnType[] = [
    { label: TeamKeysEnum.REGION, name: 'region', sort: false },
    { label: TeamKeysEnum.TECHNICIANS, name: 'technicians', sort: false },
    { label: TeamKeysEnum.AVAILABILITY, name: 'availability', sort: true },
    { label: TeamKeysEnum.REQUIRES_APPROVAL, name: 'requiresApproval', sort: true },
]
export const teamTableColumns: T.TableColumnType[] = initialTeamColumns.concat([
    { label: TeamKeysEnum.WO_TYPES, name: 'woTypes', sort: false },
    { label: TeamKeysEnum.SUPERVISORS, name: 'supervisors', sort: false },
    { label: TeamKeysEnum.ASSIGMENT_POLICY, name: 'assigment', sort: false },
    //{ label: TeamKeysEnum.AVG_WO_COST, name: 'avgWoCost', sort: false },
    //{ label: TeamKeysEnum.AVG_HOURLY_RATE, name: 'avgHourlyRate', sort: false },
    //{ label: TeamKeysEnum.TOTAL_WO_COMPLETED, name: 'totalWoCompleted', sort: false },
    //{ label: TeamKeysEnum.AVG_MONTHLY_WO, name: 'avgMonthlyWo', sort: false },
])

export const TEAMS_PER_PAGE = 50
