import { CardColumnItem, CardContainer, CardFlexRow, CardHeader, CardRowItem } from 'components/CardsContentView'
import React from 'react'
import styles from './styles.module.scss'
import CustomCheckBox from 'ui-kit/components/CustomCheckBox'
import { TeamType } from 'slices/team'
import { USER_ROLE } from 'types'
import { CheckBoxOffSVG, CheckBoxOnGreenSVG } from 'assets/ui-kit'
import { Tooltip } from '@mui/material'
import { getSvgImageTypeWo } from 'assets/workOrders-icons'

type Props = {
    team: TeamType
    handleClickTeam?: (team: TeamType) => void
    checked?: boolean
    onCheckTeam?: (team: TeamType) => void
    disabled?: boolean
}
const CardItemTeam = ({ team, handleClickTeam, onCheckTeam, checked, disabled }: Props) => {
    return (
        <CardContainer
            key={team.id}
            onClick={() => {
                handleClickTeam && handleClickTeam(team)
                onCheckTeam && onCheckTeam(team)
            }}
            checked={checked}
            disabled={disabled}
        >
            {onCheckTeam && (
                <div className={styles.checkBoxWrap}>
                    <CustomCheckBox checked={checked} />
                </div>
            )}

            <CardHeader image={team.avatar?.url} name={team?.name} />
            <CardFlexRow justifyContent="space-between">
                <CardColumnItem title="Region:" value={team.region?.name || ''} />

                <div className={styles.box}>
                    <CardColumnItem title="WO Types:" value={''} />
                    {team?.workOrderTypes?.map((item) => (
                        <Tooltip title={item} key={item} placement={'top'}>
                            <div className={styles.type}>
                                <img src={getSvgImageTypeWo(item)} alt="type" />
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </CardFlexRow>
            <CardFlexRow justifyContent="space-between">
                <CardColumnItem
                    title="# of Supervisors:"
                    value={team.users.filter((item) => item.BucketUser.role === USER_ROLE.SUPERVISOR).length}
                />
                <CardColumnItem
                    title="# of Technitians:"
                    value={team.users.filter((item) => item.BucketUser.role === USER_ROLE.TECHNICIAN).length}
                />
            </CardFlexRow>
            <CardFlexRow justifyContent="space-between">
                <CardColumnItem title="Availability:" value={team.availability} />
                <CardRowItem
                    title="Requires Approval:"
                    valueNode={team.requiresApproval ? <CheckBoxOnGreenSVG /> : <CheckBoxOffSVG />}
                />
            </CardFlexRow>
        </CardContainer>
    )
}

export default CardItemTeam
